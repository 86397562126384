import React, {
  useEffect,
  useState,
  useContext,
  useReducer,
  useRef,
} from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import { _t, getCookie } from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import Moment from "react-moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


//importing context consumer here
import { RestaurantContext } from "../../../../contexts/Restaurant";
import Paho from "paho-mqtt";

const initialOrderState = {
  totalItems: [],
  data: [],
  total: 0,
  totalPage: 0,
  currentPage: 1,
  currentOrderId: 0,
  pageSize: 1,
};

const reduceOrderPaginate = (state, action) => {
  switch (action.type) {
    case "initial":
      return paginateOrder(action.data, 1);
    case "prev":
      return paginateOrder(action.data, state.currentPage - 1);
    case "next":
      return paginateOrder(action.data, state.currentPage + 1);
    default:
      return paginateOrder(action.data, 1);
  }
};

function paginateOrder(totalOrders, current_page) {

  let currentPage = current_page || 1;
  let pageSize = 1;
  let offset = (currentPage - 1) * pageSize;
  let totalItems = totalOrders; 
  let paginatedItems = totalItems.slice(offset).slice(0, pageSize);
  let totalPage = Math.ceil(totalItems.length / pageSize);
  let currentOrderId = paginatedItems.length > 0 ? paginatedItems[0]?.id : 0;
  return {
    currentOrderId: currentOrderId,
    currentPage: currentPage,
    pageSize: pageSize,
    total: totalItems.length,
    totalPage: totalPage,
    data: paginatedItems,
    totalItems: totalItems,
  };

}

const Kitchen = () => {
  const { t } = useTranslation();

  const { loading, setLoading } = useContext(RestaurantContext);

  const [refreshInterval, setRefreshInterval] = useState(30000);

  const inputPrevRef = useRef(null);
  const inputNextRef = useRef(null);
  const inputOrderRef = useRef(null);

  //Paho mqtt
  const [mqttData, setMqttData] = useState(null);

  const [kithcenNewOrders, dispatchOrder] = useReducer(
    reduceOrderPaginate,
    initialOrderState
  );

  //get kithcen new orders
  const getAllNewOrders = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-new-orders";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(async (res) => {
        // let pgItems = paginateOrder(res.data[0], 1);
        // initialOrderState.currentPage = pgItems.currentPage;
        // initialOrderState.totalItems = pgItems.totalItems;
        // initialOrderState.data = pgItems.data;
        // initialOrderState.total = pgItems.total;
        // initialOrderState.totalPage = pgItems.totalPage;
        // initialOrderState.currentPage = pgItems.currentPage;
        // initialOrderState.currentOrderId = pgItems.currentOrderId;
        // initialOrderState.pageSize = pgItems.pageSize;

        await dispatchOrder({ type: "initial", data: res.data[0] });

        setLoading(false);
      });
  };

  const refreshOrders = () => {
    getAllNewOrders();
  };

  // by raja
  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(refreshOrders, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);

  useEffect(() => {
    //get all orders when coming to kithcen
    getAllNewOrders();
    connectPaho();
  }, []);

  const prevClick = () => {
    inputPrevRef.current.click();
  };

  const nextClick = () => {
    inputNextRef.current.click();
  };

  const ordClick = () => {
    inputOrderRef.current.click();
  };

  const getOrdState = () => {
    return kithcenNewOrders;
  };

  const mqttOrder = (liveData) => {
    switch (liveData.status) {
      case "1":
        ordClick();
        break;
      case "2":
        prevClick();
        break;
      case "3":
        nextClick();
        break;
    }
  };

  // handle mqtt here

  const connectPaho = () => {
    const clientIdRandom = Math.random().toString(23).substring(2, 13);
    const client = new Paho.Client(
      "kebab.petalblue.in",
      Number(8084),
      "/mqtt",
      `clientId-${clientIdRandom}` //clientId-mubSluondv
    );
    client.onConnectionLost = onConnectionLost;
    client.connect({
      onSuccess: onConnect,
      userName: "PetalKebWssUser",
      password: "PetalKebwSS@20#21!",
      useSSL : true
    });
    client.onMessageArrived = onMessageArrived;
    //Connection
    function onConnect() {
      let topic = "DEMO/FOOD/STATUS";
      client.subscribe(topic);
    }
    //Updation
    async function onMessageArrived(message) {
      let liveData = JSON.parse(message.payloadString);
      setMqttData(liveData);
      mqttOrder(liveData);
    }
    //Disconnection
    !client.isConnected() && setMqttData(null);
    function onConnectionLost() {
      setMqttData(null);
    }
  };

  //delete confirmation modal of waiter
   //make the order group ready here
  const handleReady = (id) => {
    setLoading(true);
    const url = BASE_URL + "/settings/mark-all-items-ready";
    let formData = {
      id,
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(async () => {
        refreshOrders();
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please refresh and try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Kitchen"))}</title>
      </Helmet>
      <main id="main" data-simplebar>
        <div className="fk-scroll--index t-mt-15 t-mb-15" data-simplebar>
          <div className="container-fluid">
            <div className="t-bg-white t-pt-10 t-pb-10 t-pl-15 t-pr-15">
              {/* next page data spin loading */}
              <div className={`${loading && "loading"}`}></div>
              {/* spin loading ends */}
              <div className="row gx-2 align-items-center">
                <div className="col-md-5 t-mb-15 mb-md-0">
                  <ul className="t-list fk-breadcrumb">
                    <li className="fk-breadcrumb__list">
                      <span className="t-link fk-breadcrumb__link text-uppercase">
                        <span className="mr-2">
                          <img
                            src="/assets/img/cooking.png"
                            alt="cooking"
                            className="img-fluid"
                            style={{ height: "40px", width: "40px" }}
                          />
                        </span>
                        <span className="mr-1">{_t(t("kitchen"))}</span>{" "}
                        {_t(t("dashboard"))}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row no-gutters g-4 mt-1">
              {kithcenNewOrders?.data ? (
                [
                  kithcenNewOrders?.data.length > 0 ? (
                    kithcenNewOrders?.data.map((item, index) => {
                      return (
                        <div
                          className="col-md-12 col-xl-12"
                          data-category={index + 1}
                        >
                          <div className="fk-order-token t-bg-white p-3 h-100">
                            <div className="fk-order-token__footer align-center text-center">
                              <button
                                type="button"
                                ref={inputPrevRef}
                                disabled={kithcenNewOrders?.currentPage == 1}
                                onClick={() => {
                                  dispatchOrder({
                                    type: "prev",
                                    data: kithcenNewOrders?.totalItems,
                                  });
                                }}
                                className="btn btn-success xsm-text text-uppercase btn-lg mr-2"
                              >
                                <i
                                  className="fa fa-chevron-left f25"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <span className="slider-count">
                                {" "}
                                {kithcenNewOrders?.currentPage} /{" "}
                                {kithcenNewOrders?.totalPage}{" "}
                              </span>
                              <button
                                ref={inputNextRef}
                                disabled={
                                  kithcenNewOrders?.currentPage ==
                                  kithcenNewOrders?.totalPage
                                }
                                type="button"
                                onClick={() => {
                                  dispatchOrder({
                                    type: "next",
                                    data: kithcenNewOrders?.totalItems,
                                  });
                                }}
                                className="btn btn-success xsm-text text-uppercase btn-lg mr-2"
                              >
                                <i
                                  className="fa fa-chevron-right f25"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>

                            <div className="d-flex justify-content-between align-center">
                              <div className="fk-order-token__footer text-left">
                                Customer :{" "}
                                <span
                                  style={{ color: "black", fontWeight: "bold" }}
                                >
                                  {" "}
                                  {item.customer_name} - {item.phone_no}{" "}
                                </span>
                              </div>
                              <div className="fk-order-token__footer">
                                <button
                                  type="button"
                                  ref={inputOrderRef}
                                  onClick={() =>
                                    handleReady(item.id)
                                  }
                                  className="btn btn-success xsm-text text-uppercase btn-lg mr-2"
                                >
                                  {_t(t("Order ready"))}
                                </button>
                                {item.is_takeaway == "1" ? (
                                  <span
                                    style={{
                                      color: "black",
                                      background: "#fdc011",
                                      padding: "5px",
                                      marginRight: "5px",
                                      borderRadius: "13px",
                                    }}
                                  >
                                    TAKEAWAY
                                  </span>
                                ) : null}
                              </div>
                            </div>

                            <div className="fk-order-token__body">
                              <div className="fk-addons-table">
                                <div className="fk-addons-table__head d-flex justify-content-between px-3">
                                  <span>
                                    {_t(t("order token"))}: #{item.token.id}
                                  </span>
                                  <span>
                                    {_t(t("ordered at"))}:{" "}
                                    <Moment format="LT">
                                      {item.token.time}
                                    </Moment>
                                  </span>
                                </div>
                                <div className="fk-addons-table__info">
                                  <div className="row g-0">
                                    <div className="col-1 text-center border-right py-2">
                                      <span className="fk-addons-table__info-text text-capitalize">
                                        {_t(t("S/L"))}
                                      </span>
                                    </div>
                                    <div className="col-5 text-center border-right py-2">
                                      <span className="fk-addons-table__info-text text-capitalize">
                                        {_t(t("food"))}
                                      </span>
                                    </div>
                                    <div className="col-4 text-center border-right py-2">
                                      <span className="fk-addons-table__info-text text-capitalize">
                                        {_t(t("Additional Info"))}
                                      </span>
                                    </div>
                                    <div className="col-1 text-center border-right py-2">
                                      <span className="fk-addons-table__info-text text-capitalize">
                                        {_t(t("QTY"))}
                                      </span>
                                    </div>
                                    <div className="col-1 text-center py-2">
                                      <span className="fk-addons-table__info-text text-capitalize">
                                        <i className="fa fa-check"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="fk-addons-table__body">
                                  {item.orderedItems.map(
                                    (thisItem, indexThisItem) => {
                                      return (
                                        <div className="fk-addons-table__body-row">
                                          <div className="row g-0">
                                            <div className="col-1 text-center border-right d-flex py-2">
                                              <span className="fk-addons-table__info-text text-capitalize m-auto f24">
                                                {indexThisItem + 1}
                                              </span>
                                            </div>
                                            <div className="col-5 text-center border-right d-flex py-2">
                                              <span className="fk-addons-table__info-text text-capitalize m-auto f24">
                                                {thisItem.food_item} (
                                                {thisItem.food_group})
                                              </span>
                                            </div>
                                            <div className="col-4 text-center border-right t-pl-10 t-pr-10 py-2">
                                              {thisItem.variation !== null && (
                                                <span className="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5 f24">
                                                  <span className="font-weight-bold mr-1">
                                                    {_t(t("variation"))}:
                                                  </span>
                                                  {thisItem.variation}
                                                </span>
                                              )}

                                              {thisItem.properties !== null && (
                                                <span className="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5 f24">
                                                  <span className="font-weight-bold mr-1">
                                                    {_t(t("properties"))}:
                                                  </span>
                                                  {JSON.parse(
                                                    thisItem.properties
                                                  ).map(
                                                    (
                                                      propertyItem,
                                                      thisIndex
                                                    ) => {
                                                      if (
                                                        thisIndex !==
                                                        JSON.parse(
                                                          thisItem.properties
                                                        ).length -
                                                          1
                                                      ) {
                                                        return (
                                                          propertyItem.property +
                                                          ", "
                                                        );
                                                      } else {
                                                        return propertyItem.property;
                                                      }
                                                    }
                                                  )}
                                                </span>
                                              )}
                                            </div>
                                            <div className="col-1 text-center border-right d-flex py-2">
                                              <span className="fk-addons-table__info-text text-capitalize m-auto f24">
                                                {thisItem.quantity}
                                              </span>
                                            </div>

                                            <div className="col-1 text-center d-flex py-2">
                                              <label className="mx-checkbox mx-checkbox--empty m-auto">
                                                <input
                                                  readOnly={true}
                                                  type="checkbox"
                                                  className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0-kitchen"
                                                  checked={true}
                                                />
                                                <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text"></span>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-8 offset-2" data-category="1">
                      <div className="fk-order-token t-bg-white p-5 text-center text-uppercase text-primary no-order">
                        {/* No order in the kitchen */}
                        <img
                          src="/assets/img/no-order.jpg"
                          alt="no order found"
                          className="img-fluid h-100"
                        />
                      </div>
                    </div>
                  ),
                ]
              ) : (
                <div className="col-12" data-category="1">
                  <Skeleton
                    className="fk-order-token t-bg-white p-3 border border-2"
                    style={{ minHeight: "560px" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Kitchen;
