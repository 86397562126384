import React, { useEffect, useContext, useState } from "react";
import "./print-bill.css";
import moment from "moment";

import { NavLink, useLocation, useHistory, useParams } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../BaseUrl";
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
} from "../../../functions/Functions";
import { useTranslation } from "react-i18next";

//context consumer
import { SettingsContext } from "../../../contexts/Settings";

const PrintBill = () => {
  let { id } = useParams();

  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let { loading, setLoading, dataPaginating } = useContext(SettingsContext);

  const [data, setData] = useState(null);
  const [order, setOrder] = useState(null);
  const [items, setItems] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [totalQty, setTotalQty] = useState(null);
  const [paymentModes, setPaymentModes] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    getBillData();
  }, []);

  const getBillData = () => {
    const url = BASE_URL + "/order/" + id;

    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.hasOwnProperty("data") && Object.keys(res.data).length != 0) {
          setData(res.data);

          if (
            res.data.hasOwnProperty("order") &&
            Object.keys(res.data.order).length != 0
          ) {
            setOrder(res.data.order);

            if (res.data.order.hasOwnProperty("token")) {
              const tokenDetails = JSON.parse(res.data.order.token);

              setToken(tokenDetails);
            } else {
              setToken(null);
            }

            if (
              res.data.order.hasOwnProperty("bill_distribution") &&
              Array.isArray(JSON.parse(res.data.order.bill_distribution))
            ) {
              const paymentDetails = JSON.parse(
                res.data.order.bill_distribution
              );

              const mergePaymentModes = paymentDetails
                .map(function (i) {
                  return i.payment_type;
                })
                .join(", ");

              console.log("PAYMENT MODES::: ", mergePaymentModes);

              setPaymentModes(mergePaymentModes);
            } else {
              setPaymentModes(null);
            }
          } else {
            setOrder(null);
            setToken(null);
            setPaymentModes(null);
          }

          if (
            res.data.hasOwnProperty("items") &&
            Array.isArray(res.data.items) &&
            res.data.items.length != 0
          ) {
            setItems(res.data.items);
            setTotalQty(res.data.items.length);
          } else {
            setItems([]);
            setTotalQty(null);
          }

          if (
            res.data.hasOwnProperty("customer") &&
            Object.keys(res.data.customer).length != 0
          ) {
            setCustomer(res.data.customer);
          } else {
            setCustomer(null);
          }
        } else {
          setData(null);
          setOrder(null);
          setItems([]);
          setCustomer(null);
          setTotalQty(null);
          setToken(null);
          setPaymentModes(null);
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const routeChange = () => {
    let path = `/dashboard/pos`;

    history.push(path);
  };

  return (
    <>
      <div class="m-4 text-center toolbar">
        <button
          class="sm-text text-capitalize btn btn-primary"
          onClick={() => {
            window.print();
          }}
        >
          Print
        </button>
        <button
          class="ml-2  btn btn-outline-danger  sm-text"
          onClick={() => {
            routeChange();
          }}
        >
          Back
        </button>
      </div>
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <table
          class="print-bill"
          style={{ margin: "auto", background: "white", width: "35%" }}
        >
          <thead>
            <tr>
              <th>
                <table
                  style={{
                    width: "100%",
                    borderBottomWidth: 1,
                    borderBottomStyle: "solid",
                    borderBottomColor: "grey",
                  }}
                >
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <img src="/assets/img/logo.png" width="50" height="50" />
                      <h3
                        style={{
                          textAlign: "center",
                          fontSize: 16,
                          textTransform: "uppercase",
                          marginTop: 10,
                          marginBottom: 5,
                        }}
                      >
                        Kebab Junction
                      </h3>
                      <h6
                        style={{
                          textAlign: "center",
                          fontSize: 11,
                          fontWeight: "400",
                        }}
                      >
                        No.D-1, 4, Karumbalai main Rd , Mellur road , KK Nagar,
                      </h6>
                      <h6
                        style={{
                          textAlign: "center",
                          fontSize: 11,
                          fontWeight: "400",
                        }}
                      >
                        Madurai , Tamilnadu 625020
                      </h6>
                      <h6
                        style={{
                          textAlign: "center",
                          fontSize: 11,
                          fontWeight: "400",
                        }}
                      >
                        Call : +91 6382 729 174
                      </h6>
                    </td>
                  </tr>
                </table>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h4
                  style={{
                    textAlign: "center",
                    fontSize: 16,
                    textTransform: "uppercase",
                    marginTop: 15,
                  }}
                >
                  Token Bill No : {token ? token.id : "-"}
                </h4>
              </td>
            </tr>
            <tr>
              <td class="plr-0" style={{ paddingLeft: 10, paddingRight: 10 }}>
                <h5
                  style={{
                    fontSize: 14,
                    marginTop: 10,
                    marginBottom: 10,
                    fontWeight: "500",
                  }}
                >
                  <b>Date :</b> {token ? moment(token.time).format("LLL") : "-"}
                </h5>
                <h5
                  style={{
                    fontSize: 14,
                    marginTop: 10,
                    marginBottom: 10,
                    fontWeight: "500",
                  }}
                >
                  <b>Customer :</b> {customer ? customer.name : "Guest"}
                  {customer && customer.phn_no && ` - ${customer.phn_no} `}
                </h5>
              </td>
            </tr>
            <tr>
              <td class="plr-0" style={{ paddingLeft: 10, paddingRight: 10 }}>
                <table style={{ width: "100%", margin: "auto" }}>
                  <thead>
                    <th
                      style={{
                        width: "70%",
                        fontSize: 14,
                        borderBottomWidth: 1,
                        borderBottomStyle: "solid",
                        borderBottomColor: "grey",
                      }}
                    >
                      Item
                    </th>
                    <th
                      style={{
                        width: "15%",
                        fontSize: 14,
                        textAlign: "center",
                        borderBottomWidth: 1,
                        borderBottomStyle: "solid",
                        borderBottomColor: "grey",
                      }}
                    >
                      Qty
                    </th>
                    <th
                      style={{
                        width: "15%",
                        fontSize: 14,
                        textAlign: "right",
                        borderBottomWidth: 1,
                        borderBottomStyle: "solid",
                        borderBottomColor: "grey",
                      }}
                    >
                      Price(₹)
                    </th>
                  </thead>
                  <tbody>
                    {items.length != 0 ? (
                      items.map((itemDetails, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              fontSize: 13,
                              paddingTop: 10,
                              paddingBottom: 10,
                            }}
                          >
                            {itemDetails.food_item}
                          </td>
                          <td
                            style={{
                              fontSize: 13,
                              textAlign: "center",
                              paddingTop: 10,
                              paddingBottom: 10,
                            }}
                          >
                            {itemDetails.quantity}
                          </td>
                          <td
                            style={{
                              fontSize: 13,
                              textAlign: "right",
                              paddingTop: 10,
                              paddingBottom: 10,
                            }}
                          >
                            {parseFloat(itemDetails.price).toFixed(2)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <td
                        style={{
                          fontSize: 13,
                          textAlign: "right",
                          paddingTop: 10,
                          paddingBottom: 10,
                          paddingRight: 30,
                        }}
                      >
                        No item(s) found
                      </td>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td
                        style={{
                          fontSize: 13,
                          paddingTop: 20,
                          paddingBottom: 5,
                          borderTopWidth: 1,
                          borderTopStyle: "solid",
                          borderTopColor: "grey",
                        }}
                      >
                        Total Quantity
                      </td>
                      <td
                        colspan="2"
                        style={{
                          fontSize: 13,
                          textAlign: "right",
                          paddingTop: 20,
                          paddingBottom: 5,
                          borderTopWidth: 1,
                          borderTopStyle: "solid",
                          borderTopColor: "grey",
                        }}
                      >
                        {totalQty || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontSize: 13,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        Total Amount
                      </td>
                      <td
                        colspan="2"
                        style={{ fontSize: 13, textAlign: "right" }}
                      >
                        ₹.
                        {order && order.order_bill
                          ? parseFloat(order.order_bill).toFixed(2)
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontSize: 13,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        Payment Mode
                      </td>
                      <td
                        colspan="2"
                        style={{ fontSize: 13, textAlign: "right" }}
                      >
                        {paymentModes || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontSize: 13,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        Dinning or Takeaway
                      </td>
                      <td
                        colspan="2"
                        style={{ fontSize: 13, textAlign: "right" }}
                      >
                        {order
                          ? order.is_takeaway == 1
                            ? "Takeaway"
                            : "Dinning"
                          : "-"}
                      </td>
                    </tr>
                    {order && order.is_takeaway == 1 && (
                      <tr>
                        <td
                          colspan="2"
                          style={{
                            fontSize: 13,
                            paddingTop: 5,
                            paddingBottom: 5,
                          }}
                        >
                          Takeaway Service Charge
                        </td>
                        <td style={{ fontSize: 13, textAlign: "right" }}>
                          ₹.
                          {order.service_charge
                            ? parseFloat(order.service_charge).toFixed(2)
                            : "-"}
                        </td>
                      </tr>
                    )}
                    {order && order.discount != 0 && (
                      <tr>
                        <td
                          style={{
                            fontSize: 13,
                            paddingTop: 5,
                            paddingBottom: 5,
                          }}
                        >
                          Discount
                        </td>
                        <td
                          colspan="2"
                          style={{ fontSize: 13, textAlign: "right" }}
                        >
                          (-) ₹.
                          {parseFloat(order.discount).toFixed(2)}
                        </td>
                      </tr>
                    )}
                    {/* <tr>
                                     <td colspan="2" style={{paddingTop: 5,paddingBottom: 5}}>Swiggy or Walk-in</td>
                                     <td style={{textAlign: 'right'}}>Swiggy</td>
                                 </tr> */}
                    <tr>
                      <td
                        style={{
                          fontSize: 14,
                          paddingTop: 20,
                          paddingBottom: 20,
                          fontWeight: "bold",
                          borderBottomWidth: 1,
                          borderBottomStyle: "solid",
                          borderBottomColor: "grey",
                          borderTopWidth: 1,
                          borderTopStyle: "solid",
                          borderTopColor: "grey",
                        }}
                      >
                        Grand Total
                      </td>
                      <td
                        colspan="2"
                        style={{
                          fontSize: 14,
                          textAlign: "right",
                          paddingTop: 20,
                          paddingBottom: 20,
                          fontWeight: "bold",
                          borderBottomWidth: 1,
                          borderBottomStyle: "solid",
                          borderBottomColor: "grey",
                          borderTopWidth: 1,
                          borderTopStyle: "solid",
                          borderTopColor: "grey",
                        }}
                      >
                        ₹.
                        {order && order.total_payable
                          ? parseFloat(order.total_payable).toFixed(2)
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3">
                        <h5
                          style={{
                            textAlign: "center",
                            fontSize: 12,
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          Thank you for visit
                        </h5>
                        <h5
                          style={{
                            textAlign: "right",
                            fontSize: 12,
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          POS Partner : Petal Automation
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3">
                        <hr></hr>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PrintBill;
