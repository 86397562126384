import React, { useState, useEffect } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

//functions
import { getCookie } from "../functions/Functions";

//3rd party packages

// creating context api
const MasterContext = React.createContext();

const MasterProvider = ({ children }) => {
  // State hooks  here
  //loading
  const [loading, setLoading] = useState(false);
  const [dataPaginating, setDataPaginating] = useState(false);

  //ledger
  const [ledgerList, setLedgerList] = useState(null);
  const [ledgerforSearch, setLedgerforSearch] = useState(null);

  //ledger
  const [expenseList, setExpenseList] = useState(null);
  const [expenseforSearch, setExpenseforSearch] = useState(null);

  //useEffect- to get data on render
  useEffect(() => {
    //call- unauthenticated

    //call if authenticated
    if (getCookie() !== undefined) {
      getLedger();
    }
  }, []);

  //get ledger
  const getLedger = () => {
    setLoading(true);
    const ledgerUrl = BASE_URL + "/settings/ledger";
    return axios
      .get(ledgerUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setLedgerList(res.data.data);
        setLedgerforSearch(res.data.data);
      });
  };

  // get paginated food groups
  const setPaginatedFoodGroup = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + `/settings/ledger?limit=10&offset=${pageNo - 1}`;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setLedgerList(res.data.data);
        setLedgerforSearch(res.data.data);
        setDataPaginating(false);
      })
      .catch(() => {});
  };

  //get expense
  const getExpense = () => {
    setLoading(true);
    const expenseUrl = BASE_URL + "/settings/expense?limit=10&offset=0";
    return axios
      .get(expenseUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setExpenseList(res.data.data);
        // setExpenseforSearch(res.data[1]);
      });
  };

  // get paginated expense
  const setPaginatedExpense = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + `/settings/expense?limit=10&offset=${pageNo - 1}`;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setExpenseList(res.data.data);
        // setExpenseforSearch(res.data[1]);
        setDataPaginating(false);
      })
      .catch(() => {});
  };

  return (
    <MasterContext.Provider
      value={{
        // common
        loading,
        setLoading,

        //ledger
        getLedger,
        ledgerList,
        setLedgerList,
        setPaginatedFoodGroup,
        ledgerforSearch,
        setLedgerforSearch,

        //expense
        getExpense,
        expenseList,
        setExpenseList,
        setPaginatedExpense,
        expenseforSearch,
        setExpenseforSearch,

        //pagination
        dataPaginating,
        setDataPaginating,
      }}
    >
      {children}
    </MasterContext.Provider>
  );
};

export { MasterContext, MasterProvider };
